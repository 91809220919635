<template>
  <div id="qschool">
    <div class="topRow qsclassAddThree">
      <div class="q-school-app">
        <div>
          <b-row>
            <b-col lg="6" md="12" sm="12">
              <div class="EnterNow">To enter the <span class="qschoolYear"> {{id}} Q-School</span> download the Alps Tour App</div>
            </b-col>
            <b-col lg="6" md="12" sm="12">
              <b-row class="q-schoolApp-block">
                <b-col lg="6" md="12" sm="12">
                  <a href="https://apps.apple.com/gb/app/alps-tour-golf/id507475853" target="_blank">
                    <div id="apple"></div>
                  </a>
                </b-col>
                <b-col lg="6" md="12" sm="12">
                  <a href="https://play.google.com/store/apps/details?id=com.alps.tour.golf&hl=en_GB&gl=US" target="_blank">
                    <div id="google"></div>
                  </a>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <div class="paddingBlock">
      <b-row class="blockAppPic">
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-1.png">
          <div class="instructBlock">
            <span class="textBlock">
              To enter Q-School download the Alps Tour App
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-2.png">
          <div class="instructBlock">
            <span class="textBlock classAdded">
              Please press on the profile icon in the top right hand corner
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/10/alps-qschool-3_24_720.png">
          <div class="instructBlock">
            <span class="textBlock">
              Select the players tab on the right and press the "Create New Account" button
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-4.png">
          <div class="instructBlock">
            <span class="textBlock">
              Please press "Register for Q-School"
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-5.png">
          <div class="instructBlock">
            <span class="textBlock">
              Run through the wizard and complete registration
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-6.png">
          <div class="instructBlock">
            <span class="textBlock">
             Go to schedule and press "Q-School"
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-7.png">
          <div class="instructBlock">
            <span class="textBlock">
              Enter and pay
            </span>
          </div> 
        </b-col>
      </b-row>
    </div>

    <b-card-header header-tag="nav" class="dropdown-seasons">
      <b-nav pills>
        <b-dropdown :text="this.id + ' Season'">
          <b-dropdown-item
            v-on:click="changeYear"
            v-for="(seasons,
            index) in tm_params.seasons.seasons_entry.slice().reverse()"
            :key="index"
            :title="seasons.code"
            :year="seasons.code"
            >{{ seasons.desc }}</b-dropdown-item
          >
        </b-dropdown>
      </b-nav>
    </b-card-header>
    <template v-if="tourns === ''">
      <div class="no-data-available">
        No Data Available for this Season 
      </div>
    </template>
  
    <div class="col-md-12 col-sm-12" v-show="id >= '2022'">
      <div v-for="(value, index) in data" :key="index">
        <b-row class="playerprofile-blockList">
          <b-col>
            <div>
              <b-link :to="'/tournament/' + value.code + '/' + id">
                <h3 class="tour-school-tag">{{value.short_name}}</h3>
              </b-link>
            </div>
          </b-col>
          <b-col>
            <div>
              <b-dropdown id="dropdown-1" text="Tournament Info " class="m-md-2 dropdownArticle">
                <b-dropdown-item v-for="(tourn, index) in value.fact_sheets.fact_sheet" :key="index" :href="tourn.fact_sheet_file" target="_blank" v-show="tourn.fact_sheet_desc" class="dropdownArticle">
                  {{ tourn.fact_sheet_desc }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <table class="table table-striped tourschool-table">
          <thead>
            <tr class="PlayerProfile-title">
              <th class="venue"><span class="venText">Venue</span></th>
              <th class="roads">Rounds 1 - {{value.rounds}}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="playerslistodd">					
              <td class="table-pos courseNameTable">
                  <span class="pos-oom venText">{{value.course}}</span>
              </td>
              <td class="dates">
                {{value.dates}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-row>
      <b-col>
        <div class="outerqschoolDesc" v-if="wpInfo[0].acf.qschool_description.length > 0">
          <p class="qschoolDesc" v-html="wpInfo[0].acf.qschool_description"></p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "q-school",
  props: ["id", "fields", "code"],
  components: {
  },
  data() {
    return {
      wpInfo: [],
      tm_params: [],
      data: [],
      tourns: [],
      allow_qschool: ""
    };
  },
  created() {
    const json = "wp/v2/pages?slug=q-school-2";
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.wpInfo = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  methods: {
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      if ( this.allow_qschool == 'B' ||  this.allow_qschool == 'W') {
      return (
        this.id = id,
        this.callTwo(id)
      ); 
      } else {
        return (
          this.id = '2022',
          this.callTwo(id)
        );
      }
    },
    callOne() {
      apiCall
        .tmParamsBase()
        .then(({ data }) => {
          this.tm_params = data;
          this.id = data.tm_params.season_code;
          this.allow_qschool = data.tm_params.allow_qschool;
          console.log("this.allow_qschool --  183")
          console.log(this.allow_qschool)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
      return console.log("one");
    },
    callTwo() {
      if (this.allow_qschool == 'B' || this.allow_qschool == 'W') {
        apiCall
        .tmqsYear(this.id)
        .then(({ data }) => {
          this.data = data.tournaments.tournaments_entry;
          console.log("this.data -- line 96")
          console.log(this.data)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        }); 
      } else {
        console.log('TEST Line 209')
        apiCall
        .tmqsYear(this.id)
        .then(({ data }) => {
          this.data = data.tournaments.tournaments_entry;
          console.lo("this.data -- line 96")
          console.lo(this.data)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        }); 
      }
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmParamsBase();
      this.tm_params = first_call.data;
      this.id = first_call.data.tm_params.season_code;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmqsYear(this.id);
      if (Array.isArray) {
        this.data = second_call.data.tournaments.tournaments_entry; 
      } else {
        this.data = [second_call.data.tournaments.tournaments_entry];
      }
      this.tourns = second_call.data.tournaments;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
::v-deep .dropdownArticle > button {
  margin: 0px;
  margin-bottom: 0px;
  text-align: center;
}
::v-deep div#dropdown-1 {
  float: right;
  margin-right: 5em!important;
}
.paddingBlock {
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 100px;
  margin-bottom: 50px;
}
.imageBack {
  display: block;
  width: 100%;
}
.numberSec {
  color: #fff;
  background: #263056;
  width: 70px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: auto;
  border-radius: 20px;
}
.instructBlock {
  text-align: center;
  /* margin-top: -20px; */
}
span.numBlock {
  text-align: center;
}
span.textBlock {
  text-align: center;
  color: #848792;
  font-size: 12px;
  line-height: 1.4!important;
  display: block;
  margin-top: 20px;
}
span.qschoolYear {
  font-weight: bold;
}
#apple {
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/ASB.png');
  height: 75px;
  z-index: 9999;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
#apple:hover {
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/ASW.png');
}
#google {
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/GPB.png');
  height: 75px;
  z-index: 9999;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
#google:hover {
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/GPW.png');
}
.no-data-available {
  text-align: center;
  font-weight: bold;
  color: #263056;
  font-size: 24px;
  margin-bottom: 30px;
}
.outerqschoolDesc {
  padding: 30px;
}
p.qschoolDesc {
  color: #212529;
  font-size: 14px;
}
h3.tour-school-tag:hover {
  color: #337ab7;
}
span.venText {
  padding-left: 15px;
}
th.venue {
  width: 50%;
}
td {
  padding: 0.5rem 0;
}
th {
  padding: 0.5rem 0;
}
td.dates {
  font-size: 14px;
  font-size: 14px!important;
  line-height: 18px;
  color: #848792;
  vertical-align: middle;
  padding: 0 0rem;
}
span.pos-oom {
  color:#337ab7;
  font-weight: 700;
  font-size: 14px;
}
.playerprofile-blockList {
    background-color: #263056;
    padding: 0px 0px 0px 10px;
    margin-top: 3px;
}
h3.tour-school-tag {
    color: #fff;
    padding: 15px 0 15px 0px;
    font-size: 13pt;
    margin-top: 0px;
    margin-bottom: 0px;
}
::v-deep .btn-secondary {
  color: #fff;
  background-color: #263056!important;
  border-color: #263056!important;
  display: block;
  width: 200px;
  height: 40px!important;
  margin: 20px auto 0px;
  margin-bottom: 20px;
  border-radius: 0px;
}
nav.card-header.dropdown-seasons {
  background: none;
  border-bottom: 0px;
}
.q-schoolApp-block {
  text-align: center;
  /* padding-right: 25px; */
  padding-right: 40px;
  padding-top: 15px;
}
.EnterNow {
  color: #fff;
  margin: 20px;
  font-size: 15pt;
  padding-left: 25px;
}
.q-school-app {
  background-color: #263056;
  padding-top: 15px;
  padding-bottom: 20px;
}
.topRow {
  margin-bottom: 30px;
  width: 780px;
  margin: auto;
}
.images {
  /* width: 100%; */
  width: 150px;
}
p.qschool-online {
  margin-top: 1rem;
}
img.visacardImage {
  margin-top: 30px;
  margin-bottom: 30px;
}
::v-deep .btn-secondary {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
  display: block;
  width: 200px;
  height: 50px;
  margin: 20px auto 0px;
  margin-bottom: 20px;
}
.not-collapsed {
  display: none;
}
#title {
  text-align: center;
  color: white;
  background-color: #263056;
}
.mainContent {
  text-align: center;
  margin-top: 30px;
}
.noTour{
  text-align: center;
  padding: 100px;
}
@media only screen and (max-width: 1024px) {
  .mobHide {
    display: none;
  }
  #logo {
    text-align: center;
  }
  .mainContent {
    flex-basis: auto;
  }
}
@media only screen and (max-width: 990px) {
  .row.blockAppPic {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  span.textBlock {
    text-align: center;
    color: #848792;
    font-size: 12px;
    line-height: 1.4!important;
    display: block;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  span.venText {
    padding-left: 0px;
  }
  img.images.iphone {
    margin-bottom: 15px;
  }
  .topRow {
    margin-bottom: 30px;
    width: 100%;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 480px) {
  .mobHide {
    display: none;
  }

  #logo {
    text-align: center;
  }
}
</style>
